<template>
    <div>
      <div v-if="isLoading">
        <div class="d-flex justify-center">
          <v-skeleton-loader
            v-for="n in 2" :key="n"
              class="mx-auto pa-1"
              width="600"
              type="card"
            ></v-skeleton-loader>
        </div>
        <br>
        <div class="d-flex justify-center">
          <v-skeleton-loader
              class="mx-auto pa-1"
              width="1200"
              type="card"
            ></v-skeleton-loader>
        </div>
      </div>
      <div v-else>
        <!-- DESKTOP -->
        <div class="headline black--text font-weight-bold" v-if="!isMobile()">
          Master Report
        </div>
        <list-report :dataTable="listReport" @eventValue="loadReports"></list-report>
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios'
  import ListReport from './TableReport.vue'

  export default {
    components:{
        ListReport
    },
    setup() {

      return {
        listReport:[],
        isLoading:true
      }
    },
    mounted(){
      this.loadReports()
    },
    methods: {
      loadReports(){
        this.isLoading = true;
        let token = localStorage.getItem('token')
        axios.get(
          process.env.VUE_APP_API_ENDPOINT + 'campaign', {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response){
              this.listReport = response.data.data
              let num = 1
              for(let a=0; a < this.listReport.length; a++){
                this.listReport[a].num = num++
              }
              setTimeout(() => {
                this.isLoading = false
              }, 200);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      gotToDetail(item){
        this.$router.push({name:'quotation-detail', query: {item: this.encryptData(item)}});
      },
      encryptData(val) {
        let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
        return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
      },
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      }
    },
    filters: {
      numberFormat (value) {
        return Intl.NumberFormat().format(value).replace(/,/g,'.');
      },
      labelFormat (value) {
        return value.replace(/-/g,' ');
      }
    },
  }
  </script>
