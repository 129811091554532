<template>
  <div>
    <div v-if="isLoading">
      <div v-if="!isMobile()">
        <div class="d-flex justify-center">
          <v-skeleton-loader
              class="mx-auto round-16 pa-1"
              width="1200"
              height="200"
              type="card"
            ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-5">
          <v-skeleton-loader
              class="mx-auto round-16 pa-1"
              width="1200"
              type="card"
            ></v-skeleton-loader>
        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-center mt-3 mt-10 pt-10">
          <v-skeleton-loader
            class="mx-auto mr-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto ml-1 pa-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-3">
          <v-skeleton-loader
            class="mx-auto mr-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto ml-1 pa-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-3">
          <v-skeleton-loader
            class="mx-auto mr-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto ml-1 pa-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-10 pt-10">
          <v-skeleton-loader
            class="mx-auto pa-1 round-16"
            width="1200"
            height="200"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center pt-5">
          <v-skeleton-loader
            class="mx-auto pa-1 round-16"
            width="1200"
            height="200"
            type="card"
          ></v-skeleton-loader>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-12">
          <v-card color="" class="elevation-1 mt-5 pa-5 pt-2 round-16">
            <!-- DESKTOP -->
            <div class="d-flex mb-3 mt-2" v-if="!isMobile()">
              <div>
                  <p class="mb-0 headline black--text font-weight-bold">Campaigns</p>
                </div>
              <v-btn rounded color="primary" class="text-capitalize ml-auto" @click.prevent="openDialog('insert', '')"><i class="fas fa-plus mr-2"></i>Tambah Campaign</v-btn>
            </div>
            <!-- MOBILE -->
            <div class="d-flex mb-3" v-else>
              <div>
                  <p class="mb-0 text-20 black--text font-weight-bold">Campaigns</p>
                </div>
              <v-btn rounded small color="primary" class="text-capitalize ml-auto" @click.prevent="openDialog('insert', '')"><i class="fas fa-plus mr-2"></i>Campaign</v-btn>
            </div>
              <v-data-table
                  :headers="headers"
                  :items="dataTable"
                  item-key="id"
                  :loading="loadingTable"
                  loading-text="Memuat data . . ."
                  class="elevation-0 text-capitalize"
                  :page.sync="page"
                  :items-per-page="itemsPerPage"
                  hide-default-footer
                  @page-count="pageCount = $event"
              >
              <template v-slot:[`item.user_id`]="{ item }">
                <div v-if="loadingClient" class="text-capitalize">
                  Loading...
                </div>
                <div v-else class="text-capitalize">
                  {{ findClient(item.user_id) }}
                </div>
              </template>
              <template v-slot:[`item.manager_id`]="{ item }">
                <div v-if="loadingManager" class="text-capitalize">
                  Loading...
                </div>
                <div v-else class="text-capitalize">
                  <div v-if="item.manager_id !== null && item.manager_id !== ''">
                    {{ findManager(item.manager_id) }}
                  </div>
                  <div v-else>-</div>
                </div>
              </template>
              <template v-slot:[`item.budget`]="{ item }">
                  <div v-if="item.budget > 0 ">
                      {{ item.budget | numberFormat }}
                  </div>
              </template>
              <template v-slot:[`item.start_date`]="{ item }">
                  <div v-if="item.start_date != null">
                      {{ item.start_date | dateLimit }}
                  </div>
              </template>
              <template v-slot:[`item.end_date`]="{ item }">
                  <div v-if="item.end_date != null">
                      {{ item.end_date | dateLimit }}
                  </div>
              </template>
              <template v-slot:[`item.content`]="{ item }">
                  <v-btn v-if="item.content != ''" rounded small color="primary" class="text-capitalize" @click="openLink(item.content)">Link</v-btn>
                  <div v-else>-</div>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div v-if="item.status === 1">
                  <v-btn
                    color="warning"
                    class="text-capitalize mr-2"
                    x-small
                    fab
                    @click.prevent="openDialog('edit', item.id)"
                  ><i class="fas fa-pen"></i>
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="text-capitalize mr-2"
                    x-small
                    fab
                    @click="gotToDetail(item.id)"
                  >
                    <i class="fa fa-eye"></i>
                  </v-btn>
                  <v-btn
                    color="error"
                    class="text-capitalize"
                    x-small
                    fab
                    @click.prevent="openDialog('delete', item.id)"
                  >
                    <i class="fas fa-trash"></i>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
            <div class="text-center mt-2 pt-0">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                ></v-pagination>
            </div>
            <div class="text-center pb-2">
            </div>
          </v-card>
        </div>

        <!-- DIALOG INSERT DATA -->
        <v-dialog v-model="dialog" max-width="800px">
          <v-card>
            <v-card-title>
              <span>{{ dialogItem.name }}</span>
            </v-card-title>

            <v-card-text class="pb-0">
              <v-container class="pa-0">
                <v-row class="pb-0 mb-0 pt-0 mt-0">
                  <div class="col-md-8 pb-0 mb-0 pt-0 mt-0">
                    <v-text-field
                      v-model="reportName"
                      label="Campaign Name"
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-md-4 pb-0 mb-0 pt-0 mt-0">
                    <v-select
                      v-model="clientName"
                      :items="client"
                      item-text="label"
                      item-value="value"
                      label="Client"
                      outlined
                      dense
                    ></v-select>
                  </div>
                </v-row>
                <v-row class="pb-0 mb-0 pt-0 mt-0">
                  <div class="col-md-2 pb-0 mb-0 pt-0 mt-0">
                    <v-text-field
                      v-model="qty"
                      label="Qty"
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-md-4 pb-0 mb-0 pt-0 mt-0">
                    <v-text-field
                      v-model="budget"
                      label="Budget"
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-md-3 pb-0 mb-0 pt-0 mt-0">
                    <v-menu
                        v-model="menuStart"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="nowStart"
                            label="Tgl Mulai"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="nowStart"
                          @input="menuStart = false"
                        ></v-date-picker>
                      </v-menu>
                  </div>
                  <div class="col-md-3 pb-0 mb-0 pt-0 mt-0">
                    <v-menu
                        v-model="menuEnd"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="nowEnd"
                            label="Tgl Akhir"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="nowEnd"
                          @input="menuEnd = false"
                        ></v-date-picker>
                      </v-menu>
                  </div>
                </v-row>
                <v-row class="pb-0 mb-0 pt-0 mt-0">
                  <div class="col-md-6 pb-0 mb-0 pt-0 mt-0">
                    <v-text-field
                      v-model="link"
                      label="Link Google Drive"
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-md-6 pb-0 mb-0 pt-0 mt-0">
                    <v-select
                      v-model="managerName"
                      :items="manager"
                      item-text="label"
                      item-value="value"
                      label="Manager"
                      outlined
                      dense
                    ></v-select>
                  </div>
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="text-capitalize"
                @click="dialog = false"
              >
                Batal
              </v-btn>
              <v-btn
                v-if="dialogItem.name === 'Tambah Campaign'"
                color="primary"
                class="text-capitalize"
                @click="submit"
              >
                Simpan
              </v-btn>
              <v-btn
                v-if="dialogItem.name === 'Ubah Campaign'"
                color="primary"
                class="text-capitalize"
                @click="submitEdit"
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- DIALOG LOADING -->
        <v-dialog
          v-model="loadingSave"
          persistent
          max-width="350"
        >
          <v-card>
            <div class="d-flex justify-center pa-10">
              <v-progress-circular
                v-if="!success"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <div v-else>
                <v-alert type="success">
                  {{ dialogMessage }} <button style="float:right" type="button" v-on:click="loadingSave = false" class="close right ml-5" data-dismiss="alert-success" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </v-alert>
                <!-- <i class="fas fa-check-circle"></i> -->
              </div>
            </div>
          </v-card>
        </v-dialog>

        <!-- DIALOG DELETE -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Anda yakin hapus campaign ini ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" class="text-capitalize" @click="dialogDelete = false">Batal</v-btn>
              <v-btn color="primary" class="text-capitalize" @click="deleteItem">Hapus</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
  </template>

  <script>
  import axios from 'axios'

  export default {
    props:['dataTable'],
    setup() {
      return {
          page: 1,
          pageCount: 0,
          itemsPerPage: 5,
          isLoading:true,
          loadingTable:true,
          loadingClient:true,
          loadingManager:true,
          loadingSave:false,
          success:false,
          dialog:false,
          dialogItem : {
            'name':'',
            'id':''
          },
          dialogDelete:false,
          dialogMessage:'',
          client:[],
          manager:[],
          reportName:null,
          clientName:null,
          managerName:null,
          qty:0,
          link:'',
          budget:0,
          idDelete:'',
          nowStart: '',
          nowEnd:'',
          // nowEnd: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          menuStart:false,
          menuEnd:false,
      }
    },
    async mounted() {
      this.getClient()
      this.getManager()
      if(this.dataTable){
        this.loadingTable = false
      }
    },
    computed: {
        headers() {
          return [
            {text: 'No.', value: 'num', sortable: false, align: 'center', width: '10'},
            {text: 'PIC', value: 'user_id', sortable: false, align: 'center'},
            {text: 'Manager', value: 'manager_id', sortable: false, align: 'center'},
            {text: 'Campaign Name', value: 'name', sortable: false, align: 'left'},
            {text: 'Qty', value: 'qty', sortable: false, align: 'center', width: '10'},
            {text: 'Google Drive', value: 'content', sortable: false, align: 'center'},
            {text: 'Budget', value: 'budget', sortable: false, align: 'left'},
            {text: 'Mulai', value: 'start_date', sortable: false, align: 'left'},
            {text: 'Berakhir', value: 'end_date', sortable: false, align: 'left'},
            // {text: 'Status', align: 'center', sortable: false, value: 'quotation.status',  width: '10'},
            {text: 'Aksi', align: 'center', sortable: false, value: 'action', width: '15%'},
          ]
        },
        filteredFormat(){
          if(this.selectType === 'link-bio'){
            return this.ContentFormat.filter(item => item.value === 'link-bio')
          } else {
            return this.ContentFormat.filter(item => item.value != 'link-bio')
          }
        }
      },
    methods: {
      decryptData(val) {
        let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
        let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
        return decryptData;
      },
      encryptData(val) {
        let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
        return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
      },
      gotToDetail(item){
        this.$router.push({name:'master-report-detail', query: {item: this.encryptData(item)}});
      },
      openDialog(type, id){
        this.clientName = ''
        this.managerName = ''
        this.reportName = ''
        this.qty = ''
        this.link = ''
        this.budget = ''
        this.nowStart = ''
        this.nowEnd = ''
        if(type === 'insert'){
          this.dialogItem.name = 'Tambah Campaign'
          this.dialog = true
        } else if (type === 'delete'){
          this.dialogDelete = true
          this.idDelete = id
        } else {
          this.loadReport(id)
          this.dialogItem.id = id
          this.dialogItem.name = 'Ubah Campaign'
          this.dialog = true
        }
      },
      getClient(){
        let token = localStorage.getItem('token')
        axios.get(
          process.env.VUE_APP_API_ENDPOINT + 'users/getClient', {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              let client = response.data.data
              for(let a = 0; a < client.length; a++){
                let arrayClient = {
                  'label': client[a].pic_name+' - '+client[a].business_name,
                  'value': client[a].id
                }
                this.client.push(arrayClient)
              }
              setTimeout(() => {
                this.isLoading = false
                this.loadingClient = false
              }, 200);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      getManager(){
        let token = localStorage.getItem('token')
        axios.get(
          process.env.VUE_APP_API_ENDPOINT + 'users/getManager', {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              let manager = response.data.data
              for(let a = 0; a < manager.length; a++){
                let arrayManager = {
                  'label': manager[a].pic_name+' - '+manager[a].business_name,
                  'value': manager[a].id
                }
                this.manager.push(arrayManager)
              }
              setTimeout(() => {
                this.isLoading = false
                this.loadingManager = false
              }, 200);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      submit(){
        this.dialog = false;
        this.loadingSave = true;
        let token = localStorage.getItem('token')
        axios.post(
          process.env.VUE_APP_API_ENDPOINT + 'campaign', {
            name:this.reportName,
            user_id:this.clientName,
            manager_id:this.managerName,
            budget:this.budget,
            content:this.link,
            qty: this.qty,
            date_start:this.nowStart,
            date_end: this.nowEnd
          }, {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 201){
              setTimeout(() => {
                this.dialogMessage = 'Berhasil Tersimpan'
                this.success = true
                this.refreshReport()
              }, 500);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      submitEdit(){
        this.dialog = false;
        this.loadingSave = true;
        let token = localStorage.getItem('token')
        axios.patch(
          process.env.VUE_APP_API_ENDPOINT + 'campaign/'+this.dialogItem.id, {
            name:this.reportName,
            user_id:this.clientName,
            manager_id: this.managerName,
            budget:this.budget,
            content:this.link,
            qty: this.qty,
            date_start:this.nowStart,
            date_end: this.nowEnd
          }, {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              setTimeout(() => {
                this.dialogMessage = 'Berhasil Tersimpan'
                this.success = true
                this.refreshReport()
              }, 500);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      deleteItem(){
        this.dialogDelete = false;
        this.loadingSave = true;
        let token = localStorage.getItem('token')
        axios.delete(
          process.env.VUE_APP_API_ENDPOINT + 'campaign/'+this.idDelete, {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              setTimeout(() => {
                this.dialogMessage = 'Berhasil Terhapus'
                this.success = true
                this.refreshReport()
              }, 500);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      loadReport(id){
        // this.isLoading = true;
        let token = localStorage.getItem('token')
        axios.get(
          process.env.VUE_APP_API_ENDPOINT + 'campaign/getCampaign/'+id, {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              this.itemData = response.data.data
              // for(let a = 0; a < this.itemData.length; a++){
                this.reportName = this.itemData.name
                this.clientName = this.itemData.user_id
                this.managerName = this.itemData.manager_id
                this.qty = this.itemData.qty
                this.link = this.itemData.content
                this.budget = this.itemData.budget
                this.nowStart = this.dateLimit(this.itemData.start_date)
                this.nowEnd = this.dateLimit(this.itemData.end_date)
              // }
              setTimeout(() => {
                // this.isLoading = false
              }, 200);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      refreshReport(){
        this.$emit('eventValue', true);
      },
      numberFormat (value) {
        return Intl.NumberFormat().format(value).replace(/,/g,'.');
      },
      openLink(link){
        window.open(link, '_blank');
      },
      dateLimit(value){
        return value.substring(0,10)
      },
      findClient(value){
        for(let i in this.client){
          if(this.client[i].value == value){
            return this.client[i].label
          }
        }
      },
      findManager(value){
        for(let i in this.manager){
          if(this.manager[i].value == value){
            return this.manager[i].label
          }
        }
      },
      isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    },
    filters: {
      numberFormat (value) {
        return Intl.NumberFormat().format(value).replace(/,/g,'.');
      },
      labelFormat (value) {
        return value.replace(/-/g,' ');
      },
      dateLimit(value){
        return value.substring(0,10)
      }
    },
  }
  </script>
